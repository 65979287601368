export enum SellerStates {
  CREATED = 'created',
  ACTIVATED = 'activated',
  DEACTIVATED = 'deactivated',
}

export interface SellerSummary {
  accountManagerEmail?: string;
  id: number;
  lotFormToken?: string;
  state: SellerStates;
}
