'use client';

import Carousel from 'react-material-ui-carousel';
import { DotOutline, StarFilled } from '@ghosts-inc/design-system/icons';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

const quotes = [
  {
    body: `“Ghost's seamless transactions and incredible logistics support empower me to close more deals than ever before.”`,
    author: 'Streetwear Reseller',
  },
  {
    body: `“Access to Ghost's inventory of sought-after brands has fueled a remarkable surge in my business and increased margins.”`,
    author: 'Luxury Wholesaler',
  },
  {
    body: `“Ghost is the most impactful surplus disrupter I've seen in my 25 years in business. I view them as an extension of my business.”`,
    author: 'Amazon Reseller',
  },
];
export default function Quotes() {
  return (
    <Carousel
      sx={{ width: '70%', minWidth: 320 }}
      navButtonsAlwaysInvisible
      IndicatorIcon={<DotOutline color="iconInverted" sx={{ fontSize: 12 }} />}
      indicatorIconButtonProps={{ style: { marginRight: 3, marginLeft: 3 } }}
      activeIndicatorIconButtonProps={{ className: 'CarouselActive' }}
      swipe={false}
      interval={6000} // every 6s
    >
      {quotes.map((quote) => (
        <Box
          key={quote.body}
          p={2.5}
          style={{ background: 'rgba(255,255,255,0.37)' }}
          borderRadius={2}
        >
          <Typography variant="bodyLargeLight" mb={2.5}>
            {quote.body}
          </Typography>
          <Stack direction="row" alignItems="center" mb={1.5}>
            {new Array(5).fill(0).map((_, i) => (
              <StarFilled key={i} />
            ))}
          </Stack>
          <Typography variant="bodySmallLight" color="text.secondary">
            {quote.author}
          </Typography>
        </Box>
      ))}
    </Carousel>
  );
}
