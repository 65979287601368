import(/* webpackMode: "eager" */ "/app/node_modules/@mui/material/Box/index.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/@mui/material/Link/index.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/@mui/material/Stack/index.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/@mui/material/SvgIcon/index.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/@mui/material/Typography/index.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/app/auth/(quotes)/QuoteGradient.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/components/SubmitButton/index.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/components/TwoSidesLayout/Header.tsx");
