'use client';

import { GradientBackground } from '@ghosts-inc/design-system/components';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import { UserMenu } from '@/components/UserMenu';
import { useGhostStore } from '@/legacy/store';
import Quotes from './Quotes';

const QuoteGradient = (): JSX.Element => {
  const currentUser = useGhostStore((state) => state.user);

  return (
    <GradientBackground height="100%" pb={5}>
      {Boolean(currentUser) && (
        <Box position="absolute" top={5} right={10}>
          <UserMenu isDark />
        </Box>
      )}
      <Stack direction="row" height="100%" alignItems="end" justifyContent="center">
        <Quotes />
      </Stack>
    </GradientBackground>
  );
};

export default QuoteGradient;
